<template>
  <section class="true-affinity-module-component">
    <canvas
      :ref="(el) => (canvasRef = el)"
      class="true-affinity hidden lg:block"
      height="525"
      width="1280"></canvas>
    <canvas
      :ref="(el) => (canvasRefMob = el)"
      class="true-affinity-mob block lg:hidden"
      height="850"
      width="450"></canvas>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TrueAffinityComponent",
    "CLASSNAME": "true-affinity-module-component",
    "URL": "http://vuevitewp.local/"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import { Rive, Layout, Fit, Alignment } from '@rive-app/canvas'
import intro from '@/assets/rive/trueaffinity.riv?url'
import introMob from '@/assets/rive/trueaffinity-mobile.riv?url'
import { ref, onBeforeUnmount, markRaw, onMounted, watch } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import useUtils from '@/components/composables/useUtils.js'

gsap.registerPlugin(ScrollTrigger)
const { device } = useUtils()
const canvasRef = ref()
const canvasRefMob = ref()

const animate = () => {
  canvasRef.value = markRaw(
    new Rive({
      src: intro,
      canvas: canvasRef.value,
      artboard: 'Mappable animation',
      autoplay: true,
      layout: new Layout({
        fit: Fit.FitWidth,
        alignment: Alignment.Center,
      }),
      stateMachines: 'MAIN',
    })
  )
}

const animateMobile = () => {
  canvasRefMob.value = markRaw(
    new Rive({
      src: introMob,
      canvas: canvasRefMob.value,
      artboard: 'Mappable animation',
      autoplay: true,
      layout: new Layout({
        fit: Fit.FitWidth,
        alignment: Alignment.Center,
      }),
      stateMachines: 'MAIN',
    })
  )
}

const pin = () => {
  ScrollTrigger.create({
    trigger: '.true-affinity-module-component',
    pin: '.true-affinity',
    pinSpacing: false,
    start: 'top center-=200',
    end: 'bottom-=500 center-=150',
    markers: false,
  })
  ScrollTrigger.create({
    trigger: '.true-affinity-module-component',
    start: 'top center+=300',
    end: 'bottom-=500 center',
    onUpdate: (self) => {
      if (canvasRef.value) {
        let inputs
        if (typeof canvasRef.value.stateMachineInputs === 'function') {
          inputs = canvasRef.value?.stateMachineInputs('MAIN')
        }
        if (inputs) inputs[0].value = self.progress * 100
      }
    },
    markers: false,
  })
}

const pinMob = () => {
  ScrollTrigger.create({
    trigger: '.true-affinity-module-component',
    pin: '.true-affinity-mob',
    start: 'top+=80 center-=200',
    end: 'bottom-=500 center-=150',
    markers: false,
  })
  ScrollTrigger.create({
    trigger: '.true-affinity-module-component',
    start: 'top-=300 center',
    end: 'bottom-=240 center',
    onUpdate: (self) => {
      if (canvasRefMob.value) {
        let inputs
        if (typeof canvasRefMob.value.stateMachineInputs === 'function') {
          inputs = canvasRefMob.value.stateMachineInputs('MAIN')
        }
        if (inputs) inputs[0].value = self.progress * 100
      }
    },
    markers: false,
  })
}

watch(device, () => {
  ScrollTrigger.refresh()
})

onMounted(() => {
  // create new rive instances and scrollTriggers
  animate()
  pin()
  animateMobile()
  pinMob()
})

onBeforeUnmount(() => {
  ScrollTrigger.killAll()
  if (canvasRef.value && canvasRef.value.hasOwnProperty('cleanup'))
    canvasRef.value.cleanup()
  if (canvasRefMob.value && canvasRefMob.value.hasOwnProperty('cleanup'))
    canvasRefMob.value.cleanup()
})
</script>

<style lang="scss" scoped>
.true-affinity-module-component {
  @apply container px-10 md:px-40 lg:px-0 h-[600vh];
  canvas {
    width: 100%;
  }
}
</style>
