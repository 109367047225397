<template>
  <div class="wrapper">
    <main>
      <div
        class="section-404 container w-full h-[60vh] max-w-screen-xl mx-auto flex flex-col justify-center my-20">
        <section class="text-center mb-6">
          <p>
            Take me to the
            <a
              href="#"
              @click="router.go(-1)"
              >previous</a
            >
            page
          </p>
        </section>
        <section
          class="error-404 module flex flex-col items-center justify-center">
          <h1 class="h2 mb-6 text-center">Your page can’t be found :(</h1>
          <div class="error404-message text-center element-line line-blue">
            <p>
              Take me back
              <a href="/">home</a>
            </p>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()

store.updateDocTitle({
  parts: ['Page not found', store.site.name],
})
</script>

<style lang="scss" scoped>
.section-404 {
  a {
    @apply underline text-text;
  }
}
</style>
