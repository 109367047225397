<template>
  <footer
    v-if="!store.site.loading && acf_options"
    class="site-footer">
    <div class="gutter-wrapper">
      <p class="rights text-center lg:text-left">
        Copyright {{ currentYear }} Andrew Howlett. All rights reserved.
      </p>
      <div class="cta">
        <ButtonGlobal
          v-for="(submenuItem, subIndex) of menu"
          :key="`sub-${subIndex}`"
          :url="submenuItem.url"
          :text="submenuItem.content"
          :disabled="submenuItem.class[0] === 'disabled' ?? false"
          :xl="false"
          :white="false"
          :invert="true"
          :target="submenuItem.target" />
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeMount } from 'vue'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'

const store = useStore()
const acf_options = ref(null)
const menu = ref(store.menu({ name: 'top-menu' }))
const currentYear = computed(() => {
  return new Date().getFullYear()
})

onBeforeMount(async () => {
  acf_options.value = await store.getAcfOptions()
})
</script>

<style lang="scss" scoped>
.site-footer {
  @apply container px-1 mx-auto bg-white pb-10;
  .gutter-wrapper {
    @apply flex flex-col-reverse lg:flex-row justify-between;
    .cta:deep() {
      @apply flex flex-row mb-10 justify-center lg:justify-start;
      a:not(:last-child) {
        @apply mr-6;
      }
    }
  }
}
</style>
