<template>
  <section class="modal-container">
    <div
      v-if="modalopen"
      class="modal"
      role="dialog"
      aria-label="site Modal"
      @click="closeModal">
      <button
        class="close-modal"
        @click="closeModal">
        <div class="bar-container">
          <div class="bars bar-1" />
          <div class="bars bar-2" />
        </div>
      </button>
      <slot />
    </div>
  </section>
</template>

<script setup>
import { watch, onUnmounted } from 'vue'
import gsap from 'gsap'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

const { locoScroll } = useSmoothScroll()
const props = defineProps(['modalopen'])
const emit = defineEmits(['closemodal'])

const keyHandler = (e) => {
  if (e.keyCode === 27) {
    closeModal()
  }
}

const closeModal = async () => {
  await gsap.fromTo(
    '.modal-container',
    {
      opacity: 1,
    },
    {
      opacity: 0,
    }
  )
  locoScroll.value.start()
  emit('closemodal')
}

const openModal = async () => {
  await gsap.fromTo(
    '.modal-container',
    {
      opacity: 0,
    },
    {
      opacity: 1,
    }
  )
  locoScroll.value.stop()
  document.addEventListener('keydown', keyHandler, { passive: true })
}

watch(
  () => props.modalopen,
  (newVal) => {
    if (newVal) openModal()
  }
)

onUnmounted(() => {
  document.removeEventListener('keydown', keyHandler, false)
})
</script>

<style lang="scss" scoped>
.modal {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  position: fixed;
  padding: 5% 10% !important;
  top: 0;
  left: 0;

  z-index: 10;
  overflow-y: hidden;
  overflow-x: hidden;

  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    @apply absolute right-5 top-5
    h-20 w-20 rounded-full bg-black;

    .bar-container {
      @apply relative transform -rotate-45;

      .bars {
        @apply absolute bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      }
      .bar-1 {
        @apply w-[55px] h-[1px];
      }
      .bar-2 {
        @apply w-[1px] h-[55px];
      }
    }
  }
}
</style>
