<template>
  <section class="blog-list-module-component">
    <h1>{{ data.title }}</h1>
    <p>{{ data.text_content }}</p>
    <div
      v-if="blogData"
      class="blogs">
      <div
        v-for="(entry, index) of blogData"
        :key="`blog-entry${index}`"
        class="single-blog">
        <div class="featured-image">
          <img
            :src="
              entry.featured_image.medium ? entry.featured_image.medium : ''
            "
            alt="" />
        </div>
        <div class="content">
          <h2>{{ entry.title.rendered }}</h2>
          <p v-html="entry.excerpt.rendered" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "BlogListComponent",
    "CLASSNAME": "blog-list-module-component",
    "URL": "http://vuevitewp.local/"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import { useStore } from '@/stores/main'
import { onBeforeMount, ref } from 'vue'

const store = useStore()
const blogData = ref()
const props = defineProps(['data'])
const postsRequest = {
  type: 'posts',
  params: {
    include: props.data.posts_to_show,
  },
  showLoading: true,
}

onBeforeMount(async () => {
  await store.getItems(postsRequest)
  const data = store.requestedItems(postsRequest)
  blogData.value = data
})
</script>

<style lang="scss" scoped>
.blog-list-module-component {
  @apply w-full max-w-screen-xl mx-auto mt-28 py-8 lg:py-10 px-6 lg:px-6;
  h1 {
    @apply text-3xl font-bold mb-6;
  }
  .blogs {
    @apply mt-10;
    .single-blog {
      @apply py-6 border-b border-b-gray-300 flex flex-col md:flex-row items-center;
      .featured-image {
        @apply w-full md:w-3/12 pb-10 md:pb-0;
        img {
          @apply w-full object-cover;
        }
      }
      .content {
        @apply px-6 w-full;
        h2 {
          @apply font-bold text-xl;
        }
      }
    }
  }
}
</style>
