<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <canvas
        :ref="(el) => (canvasRef = el)"
        height="70"
        width="500"></canvas>
    </div>
  </div>
</template>

<script setup>
import { Rive } from '@rive-app/canvas'
import intro from '@/assets/rive/intro.riv?url'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const { setIntroComplete } = useIntroShown()
const { locoScroll } = useSmoothScroll()
const canvasRef = ref()
const animationRef = ref()

const animate = () => {
  if (!locoScroll.value) {
    setTimeout(() => {
      animate()
    }, 100)
  } else {
    locoScroll.value.stop()
    animationRef.value = new Rive({
      src: intro,
      canvas: canvasRef.value,
      autoplay: true,
      stateMachines: 'MAIN',
      onStateChange: (e) => {
        setTimeout(() => {
          if (e.data.find((x) => x === 'exit')) {
            locoScroll.value.start()
            setIntroComplete()
          }
        }, 500)
      },
    })
  }
}

onMounted(() => {
  animate()
})

onBeforeUnmount(() => {})
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-50 bg-gradient-to-tr from-pageLoadingfrom to-pageLoadingTo backdrop-blur-xl;
  .intro-container {
    @apply left-0 flex items-center justify-center w-full h-[100vh] bg-cover bg-no-repeat relative;
    canvas {
      @apply w-[90%] h-auto md:w-[500px] m-auto;
    }
  }
}
</style>
