<template>
  <div
    ref="menuContainer"
    class="overlay-menu relative"
    :class="props.active ? 'active' : ''">
    <div class="main-container">
      <div
        class="close-mobile"
        :class="props.active ? 'active' : ''"
        @click="close()">
        <span class="line line-one" />
        <span class="line line-three" />
      </div>
      <div
        v-if="menu"
        class="menu">
        <div
          v-for="(menuItem, menuIndex) of menu"
          :key="`menu-index-${menuIndex}`"
          class="menu-item">
          <router-link
            :to="menuItem.url"
            @click="close()"
            >{{ menuItem.content }}</router-link
          >
        </div>
        <div
          class="mouse-follow"
          :style="{
            left: `${x}px`,
            top: `${y}px`,
          }"></div>
      </div>
      <div class="contact">
        <div class="contact-left">
          <template
            v-for="(submenuItem, subIndex) of submenu"
            :key="`sub-${subIndex}`">
            <ButtonGlobal
              v-if="subIndex === 0"
              :url="submenuItem.url"
              :text="submenuItem.content"
              :disabled="submenuItem.class[0] === 'disabled' ?? false"
              :xl="false"
              :white="true"
              :invert="false"
              :target="submenuItem.target" />
          </template>
        </div>
        <div class="contact-right">
          <a
            v-if="acf_options?.twitter"
            :href="acf_options.twitter"
            target="_blank"
            >Twitter</a
          >
          <a
            v-if="acf_options?.linkedin"
            :href="acf_options.linkedin"
            target="_blank"
            >LinkedIn</a
          >
          <a
            v-if="acf_options?.facebook"
            :href="acf_options.facebook"
            target="_blank"
            >Facebook</a
          >
          <br />
          <a
            href="https://www.struck.com/"
            target="_blank"
            >Struck</a
          >
          <a
            href="https://strktr.com/"
            target="_blank"
            >STRKTR</a
          >
          <a
            href="https://nicasource.com/"
            target="_blank"
            >NicaSource</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import ButtonGlobal from '../../utility/ButtonGlobal.vue'
import { useMouse } from '@vueuse/core'
import { useStore } from '@/stores/main'
import { ref, onBeforeMount, onMounted, watch } from 'vue'
import gsap from 'gsap'

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const acf_options = ref()
const emit = defineEmits(['close'])

const { locoScroll } = useSmoothScroll()
const menuContainer = ref()
const parentEl = menuContainer

const extractor = (event) => {
  return [event.clientX, event.clientY]
}

const { x, y } = useMouse({ target: parentEl, type: extractor })

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
    required: false,
  },
  submenu: {
    type: Array,
    default: {},
    required: false,
  },
})

const close = () => {
  if (!store.site.loading) emit('close')
  else
    setTimeout(() => {
      close()
    }, 100)
}

onBeforeMount(async () => {
  acf_options.value = await store.getAcfOptions()
})

onMounted(() => {
  gsap.set('.menu-item > a', { top: '100%' })
  gsap.set('.contact', { opacity: 0, left: -30 })
})

watch(
  () => props.active,
  () => {
    if (props.active) {
      if (locoScroll.value) locoScroll.value.stop()
      gsap.to('.contact', { opacity: 0, left: -30 })
      gsap.to('.menu-item > a', {
        top: 0,
        stagger: 0.2,
        delay: 0.6,
      })
      gsap.to('.contact', { opacity: 1, left: 0, delay: 1 })
    } else {
      if (locoScroll.value) locoScroll.value.start()
      gsap.to('.menu-item > a', { top: '100%', duration: 0.3 })
      gsap.to('.contact', { opacity: 0 })
    }
  }
)
</script>
<style lang="scss" scoped>
.overlay-menu {
  @apply w-screen h-0 bg-mainBlue absolute transition-height duration-1000 ease-in-out flex items-center overflow-hidden z-50;

  .main-container {
    @apply container flex flex-col lg:flex-row items-center lg:items-end gap-20 lg:gap-0;
    .close-mobile {
      @apply block lg:hidden absolute top-5 right-5 translate-y-3;

      .line-one {
        @apply block h-[1px] w-[55px] bg-black transition-all duration-1000 ease-in-out transform rotate-0 mb-2;
      }
      .line-three {
        @apply block h-[1px] w-[55px] bg-black transition-all duration-1000 ease-in-out transform -rotate-0;
      }

      &.active {
        .line-one {
          @apply block h-[1px] w-[55px] bg-white transform rotate-45 mb-0;
        }
        .line-three {
          @apply block h-[1px] w-[55px] bg-white transform -rotate-45;
        }
      }
    }

    .menu {
      @apply flex flex-col items-start basis-full xl:basis-8/12 justify-end;
      .mouse-follow {
        @apply w-96 h-96 rounded-full -translate-x-1/2 -translate-y-1/2 absolute bg-white pointer-events-none opacity-5 blur-3xl
      transition-transform duration-300 ease-in-out;
      }
      .menu-item {
        @apply overflow-hidden h-[80px] lg:h-[120px];
        a {
          @apply relative text-[80px] lg:text-[120px] font-PolySansSlim leading-[95%] text-white 
        transition duration-150 ease-in-out transform translate-x-0 opacity-40;
          @apply hover:translate-x-4 hover:opacity-100;
          -webkit-text-stroke: 2px;
          -webkit-text-fill-color: transparent;
          &:hover {
            -webkit-text-fill-color: white;
          }
          &.router-link-active {
            @apply opacity-100;
            -webkit-text-fill-color: white;
          }
        }
        &:hover ~ .mouse-follow {
          @apply scale-50 opacity-10;
        }
      }
    }

    .contact {
      @apply relative flex flex-row basis-full xl:basis-4/12 items-end justify-center xl:justify-start mb-3 gap-14;
      .contact-left {
        @apply inline-block lg:hidden self-start text-white;
        p {
          @apply text-white;
        }
      }
      .contact-right {
        a {
          @apply text-white medium flex flex-col;
        }
      }
    }
  }

  &.active {
    @apply h-screen;
  }
}
</style>
