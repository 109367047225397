import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../scripts/emitter'
import VueScrollTo from 'vue-scrollto'
import { useStore } from '@/stores/main'

const { url } = window.__VUE_WORDPRESS__.routing
// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const position = { left: 0, top: 0 }

    // Only way I could see to go to an anchor on another page. Possible race condition.
    if (to.hash) {
      const options = {
        container: 'body',
        easing: 'ease-in-out',
        lazy: false,
        offset: -90,
        force: true,
        cancelable: false,
        x: false,
        y: true,
      }
      const navigate = () => {
        const store = useStore()
        if (store.site.loading) {
          setTimeout(() => {
            navigate()
          }, 500)
        } else {
          VueScrollTo.scrollTo(to.hash, 500, options)
        }
      }

      navigate()
    }

    if (savedPosition) {
      position.x = savedPosition.x
      position.y = savedPosition.y

      return new Promise((resolve) => {
        emitter.on('scrollBeforeEnter', (done) => {
          resolve(position)
          done()
        })
      })
    } else {
      return new Promise((resolve) => {
        emitter.on('scrollBeforeEnter', (done) => {
          resolve(position)
          done()
        })
      })
    }
  },
})
