<template>
  <section class="videos container">
    <div
      class="videos-text"
      v-html="props.data.title" />
    <div class="videos-list">
      <div
        v-for="(item, idx) in props.data.videos"
        :key="idx"
        :href="item.url"
        class="video"
        @click="selectVideo(idx, item.is_vimeo, item.external_video_link)">
        <div class="video-thumbnail">
          <img
            class="video-thumbnail-img"
            :src="item.thumbnail.url" />
          <img
            class="video-thumbnail-play"
            src="@/assets/img/play.svg" />
        </div>
        <div class="video-content">
          <div
            class="video-content-title"
            v-html="item.title" />
          <span class="video-content-dash"> — </span>
          <div
            class="video-content-text"
            v-html="item.description" />
        </div>
      </div>
    </div>
    <Modal
      :modalopen="openModal"
      @closemodal="closeModal()">
      <div class="modal-content">
        <iframe
          ref="focusHere"
          :src="`https://player.vimeo.com/video/${props.data.videos[selected].vimeo_id}?autoplay=true`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </Modal>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "VideosComponent",
  "CLASSNAME": "videos",
  "URL": "http://vuevitewp.local/"
 }
#ENDMETA */
import { ref } from 'vue'
import Modal from '@/components/utility/Modal.vue'

const props = defineProps(['data'])

const selected = ref(0)
const openModal = ref(false)

const closeModal = () => {
  openModal.value = false
}

const selectVideo = (idx, is_vimeo, external_video_link) => {
  if (!is_vimeo) {
    window.open(external_video_link, '_blank')
  } else {
    selected.value = idx
    openModal.value = true
  }
}
</script>

<style lang="scss" scoped>
.videos {
  @apply py-10 flex flex-col
  lg:flex-row lg:justify-between
  xl:px-0;

  .videos-text {
    @apply flex-grow w-full mb-10 large leading-7
    lg:mb-0
    lg:max-w-[210px];
    &:deep(p) {
      @apply text-3xl w-full;
    }
  }

  .videos-list {
    @apply flex flex-col w-full gap-[40px]
    md:flex-row md:flex-wrap md:justify-between
    lg:justify-end lg:gap-[30px];
    .video {
      @apply flex flex-col  w-full
      md:flex-1
      lg:max-w-[290px]
      xl:max-w-[390px];

      &-thumbnail {
        @apply relative bg-red-600 h-[260px] mb-3
        lg:h-[195px] lg:mb-[30px] hover:scale-105 transition-transform duration-300 ease-in-out
        xl:h-[260px] xl:max-w-[390px] hover:cursor-pointer;
        &-img {
          @apply w-full h-full object-cover;
        }
        &-play {
          @apply absolute right-[20px] bottom-[20px];
        }
      }
      &-content {
        @apply flex flex-col text-[22px] min-h-[240px];

        &-title {
          @apply font-[600];
        }

        &-dash {
          @apply my-2;
        }
      }
    }
  }
  .modal-content {
    @apply w-full h-full flex justify-center items-center;
    iframe {
      @apply w-full h-72
        lg:h-[800px];
    }
  }
}
</style>
