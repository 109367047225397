<template>
  <div
    ref="wrapper"
    class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template v-if="module.acf_fc_layout === 'FormsComponent'">
            <Forms
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'AboutComponent'">
            <AboutComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'TrueAffinityComponent'">
            <TrueAffinityComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BlogListComponent'">
            <BlogListComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ImageComponent'">
            <ImageComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'SpacerComponent'">
            <SpacerComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'HeroComponent'">
            <HeroComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'CallToActionComponent'">
            <CallToActionComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ScrollCTAComponent'">
            <ScrollCTAComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'NewsComponent'">
            <NewsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'Anchor'">
            <Anchor
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'VideosComponent'">
            <VideosComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import Forms from '@/components/modules/FormsComponent.vue'
import AboutComponent from '@/components/modules/AboutComponent.vue'
import TrueAffinityComponent from '@/components/modules/TrueAffinityComponent.vue'
import BlogListComponent from '@/components/modules/BlogListComponent.vue'
import ImageComponent from '@/components/modules/ImageComponent.vue'
import SpacerComponent from '@/components/modules/SpacerComponent.vue'
import HeroComponent from '@/components/modules/HeroComponent.vue'
import CallToActionComponent from '@/components/modules/CallToActionComponent.vue'
import ScrollCTAComponent from '@/components/modules/ScrollCTAComponent.vue'
import NewsComponent from '@/components/modules/NewsComponent.vue'
import Anchor from '@/components/modules/Anchor.vue'
import VideosComponent from '@/components/modules/VideosComponent.vue'

import { computed, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const page = computed(() => {
  normalizeData('forms')
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}

const normalizeData = (type) => {
  if (type) {
    const filtered = store.getCurrentSingleBySlug(request)
    const rawData = store.getPosts({ type: type })
    if (filtered) {
      for (const module of filtered.acf.modules) {
        if (module.acf_fc_layout === type) {
          const data = Object.entries(rawData).map((x) => x[1])
          data.pop()
          module[type] = data
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
