<template>
  <section class="news container">
    <h2
      class="news-title"
      v-html="data.title"></h2>
    <div class="news-items">
      <a
        v-for="(item, idx) in data.posts_to_show"
        :key="idx"
        :href="
          item.is_external_post
            ? item.external_post.url.url
            : item.post.post_url
        "
        :target="
          item.is_external_post ? item.external_post.url.target : '_self'
        "
        class="single-news">
        <h3 class="single-news-title">
          <p
            v-html="
              item.is_external_post
                ? item.external_post.title
                : item.post.post_title
            " />
        </h3>
        <p
          class="single-news-date"
          v-html="
            convertToDate(
              item.is_external_post
                ? item.external_post.date
                : item.post.post_date
            )
          " />
        <p
          class="single-news-content"
          v-html="
            item.is_external_post
              ? item.external_post.excerpt
              : item.post.post_excerpt
          " />
        <div class="single-news-link">
          <img src="@/assets/img/link-arrow.svg" />
        </div>
      </a>
    </div>
  </section>
</template>

<script setup>
defineProps(['data'])

/* #META
 {
  "NAME": "ScrollCTAComponent",
  "CLASSNAME": "news",
  "URL": "http://vuevitewp.local/"
 }
#ENDMETA */

const convertToDate = (date) => {
  const dateObject = new Date(date)
  var year = dateObject.getFullYear()

  var month = dateObject.getMonth()
  const monthsText = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  month = monthsText[month]

  var day = dateObject.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return month + ' ' + day + ', ' + year
}
</script>

<style lang="scss" scoped>
.news {
  @apply py-10 flex flex-col xl:px-0;

  &-title {
    @apply text-[30px] mb-[72px] md:mb-28;
  }
  .news-items {
    @apply flex flex-col gap-24 
    md:flex-wrap md:flex-row md:gap-0 md:gap-y-14
    md:justify-between;
    .single-news {
      @apply flex flex-col md:w-[45%];

      &-title {
        p {
          @apply text-[28px] font-PolySansMedian;
        }
      }
      &-date {
        @apply text-lg my-3;
      }

      &-content {
        @apply text-lg mb-[35px] leading-[21px];
      }
      &-link {
        @apply md:mt-auto;
        img {
          //@apply w-6 h-6;
        }
      }
      .single-news-link {
        img {
          @apply transition-all duration-150 ease-in-out;
        }
      }
      &:hover {
        img {
          @apply scale-150;
        }
      }
    }
  }
}
</style>
