<template>
  <div class="search-container">
    <a
      id="closeImg"
      @click="closeSearch">
      <img
        :src="searchClose"
        alt />
    </a>
    <div class="search">
      <div class="search__input">
        <input
          v-model="searchValue"
          placeholder="search something..."
          type="text"
          @keyup="getResult" />
        <img
          :src="searchIcon"
          alt="Search icon" />
      </div>
      <div class="search__results">
        <a
          v-for="searchItem in searchResult"
          :key="searchItem.id"
          :href="searchItem.url"
          class="search__results__item"
          @click="closeSearch">
          <div class="left">
            <h3>{{ searchItem.title }}</h3>
            <div v-html="searchItem.content"></div>
          </div>
          <div class="right">
            <span>{{ searchItem.subtype }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from '../../../stores/main'
import { gsap } from 'gsap'
import { debounce } from 'debounce'
import searchIcon from '@/assets/img/search.svg'
import searchClose from '@/assets/img/close.svg'

const store = useStore()
// const props = defineProps(['display'])
const emit = defineEmits(['display-search'])

const searchResult = ref([])
const searchValue = ref('')
const request = ref({
  type: 'search',
  params: {
    search: searchValue.value,
  },
})

onMounted(() => {
  gsap.fromTo(
    '.search-container',
    { autoAlpha: 0 },
    {
      autoAlpha: 0.96,
    }
  )
})

const closeSearch = () => {
  emit('display-search')
}

const getResult = () => {
  return debounce(getResultFromApi, 1000)
}

const getResultFromApi = () =>
  store
    .getItems(request.value)
    .then(() => (searchResult.value = store.requestedItems(request.value)))
</script>

<style lang="scss" scoped>
.search-container {
  @apply fixed inset-0 z-2 w-[100vw] h-[100vh] bg-black opacity-95 px-[10vw] py-[10vh];

  .search {
    @apply mt-5;
    &__input {
      @apply border-b-2 border-white flex flex-row justify-between h-16;
      input {
        @apply w-full bg-transparent border-none text-white text-4xl text-center focus:outline-none focus:ring-0 placeholder-white placeholder-opacity-50;
      }
      img {
        @apply w-8 my-4;
      }
    }
    &__results {
      &__item {
        @apply w-full flex flex-row justify-between items-center border-b p-2 my-6 hover:bg-[#ffffff1a];
        .left {
          h3 {
            @apply text-xl text-white text-left;
          }
          div {
            @apply text-base text-white text-left w-1/2  line-clamp-2;
          }
        }
        .right {
          @apply h-full bg-white text-text py-2 px-4 rounded-full;
        }
      }
    }
  }
  #closeImg {
    @apply absolute top-[5%] right-[5%] cursor-pointer;
    img {
      @apply w-10;
    }
  }
}
</style>
