<template>
  <div
    ref="wrapper"
    class="wrapper">
    <main class="main">
      <section id="team-member-info">
        <div class="top-area">
          <div class="back-button"></div>
        </div>
        <div
          v-if="member && member.acf"
          class="team-member">
          <div class="team-picture">
            <img
              v-if="member.acf.teammate_data.teammate_bio.headshot.url"
              v-lazy="member.acf.teammate_data.teammate_bio.headshot.url"
              :alt="member.acf.teammate_data.teammate_bio.headshot.alt" />
            <div class="teammate-cta"></div>
          </div>
          <div class="team-bio">
            <div class="team-heading-area">
              <h2 class="teammate-name text-text">
                {{ member.acf.teammate_data.teammate_info.name }}
              </h2>
              <div class="role-and-company">
                <p>
                  {{ member.acf.teammate_data.teammate_info.title }}
                  <template
                    v-if="
                      member.acf.teammate_data.teammate_info.company &&
                      member.acf.teammate_data.teammate_info.company !== ''
                    "
                    >at
                    {{
                      member.acf.teammate_data.teammate_info.company
                    }}</template
                  >
                  <template
                    v-if="
                      member.acf.teammate_data.teammate_info.portfolio &&
                      member.acf.teammate_data.teammate_info.portfolio !== ''
                    "
                    >at
                    {{
                      member.acf.teammate_data.teammate_info.portfolio
                    }}</template
                  >
                </p>
              </div>
            </div>
            <div
              class="teammate-bio-content"
              v-html="member.acf.teammate_data.teammate_bio.bio" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script setup>
import { computed, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
// import pageview from 'vue-gtag'
import useUtils from '@/components/composables/useUtils.js'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle /*convertToRelative */ } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const getMember = () => {
  store.getSingleBySlug(request.value)
  if (member.value) {
    store
      .updateDocTitle({
        parts: [decodeTitle(member.value.title.rendered), store.site.name],
      })
      .then(() => {
        // pageview({
        //   page_path: convertToRelative(member.value.link),
        //   page_title: document.title,
        //   page_location: window.location.href,
        // })
      })
  } else {
    router.replace('/404')
    // pageview({
    //   page_path: '/404',
    //   page_title: document.title,
    //   page_location: window.location.href,
    // })
  }
}

onBeforeMount(() => getMember())
</script>

<style lang="scss" scoped>
#team-member-info {
  @apply w-full mt-10 mb-40 p-4 xl:p-4;
  .top-area {
    @apply max-w-screen-xl mx-auto mb-10;
  }
  .team-member {
    @apply max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16;
    .team-picture {
      img {
        @apply object-cover object-center mx-auto max-h-[500px] md:max-h-max md:min-h-[80vh];
      }
      .teammate-cta {
        @apply grid grid-cols-1 lg:grid-cols-2 mt-10 lg:mt-16 gap-4;
        a {
          @apply mx-auto;
        }
      }
    }
    .team-bio {
      .team-heading-area {
        @apply border-solid border-gray-400 border-l pl-5 max-w-[300px] mb-12;
        .teammate-name {
          @apply mb-4;
        }
      }
      .teammate-bio-content:deep() {
        @apply max-w-[90%];
        p {
          @apply mb-7 text-text;
        }
      }
    }
  }
}
</style>
