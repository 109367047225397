<template>
  <section class="about-module-component">
    <div class="inner-container">
      <div class="col-one">
        <h1 v-html="data.title" />
        <div v-html="data.content" />
      </div>
      <div class="col-two">
        <div class="image-container">
          <div class="bio-img">
            <img
              :src="data.picture.url"
              :alt="data.picture.alt" />
          </div>
        </div>
        <div class="contact-about">
          <div class="cols-about col-1-about">
            <a
              v-if="acf_options?.twitter"
              class="about-contact-link"
              :href="acf_options.twitter"
              target="_blank"
              >Twitter</a
            >
            <a
              v-if="acf_options?.linkedin"
              class="about-contact-link"
              :href="acf_options.linkedin"
              target="_blank"
              >LinkedIn</a
            >
            <a
              v-if="acf_options?.facebook"
              class="about-contact-link"
              :href="acf_options.facebook"
              target="_blank"
              >Facebook</a
            >
          </div>
          <div class="cols-about col-2-about">
            <a
              class="about-contact-link"
              href="https://www.struck.com/"
              target="_blank"
              >Struck</a
            >
            <a
              class="about-contact-link"
              href="https://strktr.com/"
              target="_blank"
              >STRKTR</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "AboutComponent",
    "CLASSNAME": "about-module-component",
    "URL": "http://vuevitewp.local/"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import useUtils from '@/components/composables/useUtils.js'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])
const store = useStore()
const acf_options = ref()
const { device } = useUtils()

const pin = () => {
  console.log(device.value)
  if (device.value !== 'desktop') return
  ScrollTrigger.create({
    trigger: '.about-module-component',
    pin: '.bio-img',
    pinSpacing: true,
    start: 'top+=100 center-=200',
    end: 'bottom-=500 70%',
    markers: false,
  })
}

const animations = () => {
  gsap.fromTo(
    '.bio-img',
    { opacity: 0, filter: 'blur(10px)' },
    { opacity: 1, filter: 'blur(0px)' }
  )
}

onBeforeMount(async () => {
  acf_options.value = await store.getAcfOptions()
})

onMounted(() => {
  animations()
  pin()
})

onBeforeUnmount(() => {
  ScrollTrigger.killAll()
})
</script>

<style lang="scss" scoped>
.about-module-component {
  @apply container my-28;
  .inner-container {
    @apply flex flex-col-reverse lg:flex-row gap-12 lg:gap-32;
    .col-one:deep() {
      @apply basis-1/2 pr-0 lg:pr-24;
      h1 {
        @apply font-PolySansSlim text-[60px] leading-[94%] mb-16;
      }
      h2 {
        @apply text-[30px] font-PolySansSlim;
      }
      p {
        @apply mb-6;
      }
    }
    .col-two {
      @apply basis-1/2;
      .image-container {
        @apply w-screen md:w-auto relative -left-[15px] lg:-left-0 h-auto lg:h-[85%];
        .bio-img > img {
          @apply mb-12 lg:mb-36 w-full h-auto object-cover;
        }
      }
      .contact-about {
        @apply hidden lg:flex gap-32 border-t pt-12;
        .col-1-about {
          @apply flex flex-col font-PolySansNeutral;
        }
        .col-2-about {
          @apply flex flex-col justify-end font-PolySansNeutral;
        }
        a {
          @apply medium text-[18px] mb-2 font-PolySansNeutral;
        }
      }
    }
  }
}
</style>
