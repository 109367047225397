<template>
  <section class="hero container">
    <div class="hero-info">
      <h1
        id="hero-title"
        class="hero-info-title"
        v-html="props.data.title"></h1>
      <div
        class="hero-info-text"
        v-html="props.data.section_text" />
      <ButtonGlobal
        class="hero-info-cta w-fit"
        :url="props.data.cta.url"
        :text="props.data.cta.title"
        :xl="true"
        :white="false"
        :target="props.data.cta.target" />
      <div class="contact-hero">
        <div class="cols col-1">
          <a
            v-if="acf_options?.twitter"
            class="hero-contact-link"
            :href="acf_options.twitter"
            target="_blank"
            >Twitter</a
          >
          <a
            v-if="acf_options?.linkedin"
            class="hero-contact-link"
            :href="acf_options.linkedin"
            target="_blank"
            >LinkedIn</a
          >
          <a
            v-if="acf_options?.facebook"
            class="hero-contact-link"
            :href="acf_options.facebook"
            target="_blank"
            >Facebook</a
          >
        </div>
        <div class="cols col-2">
          <a
            class="hero-contact-link"
            href="https://www.struck.com/"
            target="_blank"
            >Struck</a
          >
          <a
            class="hero-contact-link"
            href="https://strktr.com/"
            target="_blank"
            >STRKTR</a
          >
          <a
            class="hero-contact-link"
            href="https://nicasource.com/"
            target="_blank"
            >NicaSource</a
          >
        </div>
      </div>
    </div>
    <div class="hero-img">
      <img
        :src="props.data.image.url"
        :alt="props.data.image.alt" />
    </div>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "HeroComponent",
  "CLASSNAME": "hero",
  "URL": "http://vuevitewp.local/"
 }
#ENDMETA */
import { useStore } from '@/stores/main'
import { ref, onBeforeMount, onMounted, watch } from 'vue'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import gsap from 'gsap'
import SplitType from 'split-type'
import useIntroShown from '@/components/composables/useIntroShown.js'

const store = useStore()
const props = defineProps(['data'])
const acf_options = ref()
const { introShown } = useIntroShown()
const dataLoaded = ref(false)

const animate = () => {
  const heroTL = gsap.timeline({
    defaults: {
      duration: 1,
      ease: 'power3',
    },
  })
  new SplitType('#hero-title', { types: 'lines', tagName: 'span' })
  //new SplitType('.hero-info-text')

  heroTL
    .fromTo(
      '.hero-info-title > .line',
      {
        autoAlpha: 0,
        y: -20,
      },
      {
        y: 0,
        autoAlpha: 1,
        stagger: 0.5,
        delay: 0.5,
      }
    )
    .fromTo(
      '.hero-img',
      {
        autoAlpha: 0,
        filter: 'blur(10px)',
      },
      {
        autoAlpha: 1,
        filter: 'blur(0px)',
      },
      '<'
    )
    .fromTo(
      '.hero-info-text',
      {
        autoAlpha: 0,
        x: -10,
      },
      {
        autoAlpha: 1,
        x: 0,
      },
      '>'
    )
    .fromTo(
      '.hero-info-cta',
      {
        autoAlpha: 0,
        x: -100,
      },
      {
        autoAlpha: 1,
        x: 0,
      },
      '<'
    )
}

onBeforeMount(async () => {
  acf_options.value = await store.getAcfOptions()
})

const hasDataLoaded = () => {
  if (acf_options.value) {
    dataLoaded.value = true
  } else {
    setTimeout(() => {
      hasDataLoaded()
    }, 100)
  }
}

const animateSocialMedia = () => {
  gsap.to('.hero-contact-link', {
    opacity: 1,
    x: 0,
    stagger: 0.2,
    delay: 1.5,
  })
}

const triggerSocial = () => {
  if (dataLoaded.value && introShown.value) animateSocialMedia()
  else
    setTimeout(() => {
      triggerSocial()
    }, 1000)
}

watch(dataLoaded, () => {
  gsap.set('.hero-contact-link', {
    opacity: 0,
    x: -10,
  })
})

watch(introShown, () => {
  if (introShown.value) animate()
})

onMounted(() => {
  hasDataLoaded()
  triggerSocial()
  if (introShown.value) animate()
})
</script>

<style lang="scss" scoped>
.hero {
  @apply py-16 px-0 flex flex-col lg:flex-row lg:px-[3.5rem] min-h-screen;

  &-info {
    @apply px-3 pb-16 flex flex-col basis-full lg:basis-9/12 lg:px-0;
    &-title:deep() {
      @apply mb-12 lg:max-w-[680px];
    }
    &-text {
      @apply lg:max-w-[643px];
    }

    &-cta {
      @apply flex my-8
      lg:mb-28;
    }
    .contact-hero {
      @apply hidden lg:flex gap-32;
      .col-1 {
        @apply flex flex-col font-PolySansMedian;
      }
      .col-2 {
        @apply flex flex-col justify-end font-PolySansMedian;
      }
      a {
        @apply medium font-PolySansMedian;
      }
    }
  }

  &-img {
    @apply flex basis-full lg:basis-5/12;
    img {
      @apply object-contain w-full h-full object-top;
    }
  }
}
</style>
