<template>
  <section class="scroll-cta container">
    <h1
      class="scroll-cta-title"
      v-html="props.data.title" />
    <div class="scroll-cta-bottom">
      <p
        class="scroll-cta-bottom-text"
        v-html="props.data.section_text" />
      <ButtonGlobal
        class="scroll-cta-bottom-cta w-fit"
        :text="'Scroll down   ↓'"
        :xl="true"
        :white="false"
        target=""
        @click="scroll()" />
    </div>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "ScrollCTAComponent",
  "CLASSNAME": "scroll-cta",
  "URL": "http://vuevitewp.local/"
 }
#ENDMETA */

import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

const props = defineProps(['data'])
const { locoScroll } = useSmoothScroll()

const scroll = () => {
  locoScroll.value.scrollTo('#' + props.data.anchorID, { duration: 3 })
}
</script>

<style lang="scss" scoped>
.scroll-cta {
  @apply py-10 flex flex-col gap-8
  md:gap-16 xl:px-0;

  &-bottom {
    @apply flex flex-col gap-8
    md:flex-row md:gap-10 md:items-center;
    &-text:deep(p) {
      @apply text-3xl md:max-w-[550px];
    }
    &-cta {
      @apply min-w-[180px];
    }
  }
}
</style>
