<template>
  <section class="forms-section">
    <div class="form-group">
      <div class="form-group-top">
        <h1
          class="form-section-title text-black mb-20"
          v-html="props.data.forms_module_group.form_title" />
      </div>
      <div class="form-area">
        <FormKit
          v-if="form"
          id="formKit"
          type="form"
          @submit="submitHandler">
          <FormKitSchema
            :schema="selectedForm"
            :library="library" />
        </FormKit>
        <div
          v-if="form"
          class="submit-button-container">
          <ButtonGlobal
            :text="submitButton"
            :xl="true"
            :white="false"
            @click="$formkit.submit('formKit')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "FormsComponent",
  "CLASSNAME": "forms-section",
  "URL": "http://vuevitewp.local/"
 }
#ENDMETA */
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '../../stores/main'
import { FormKitSchema } from '@formkit/vue'
import { reset } from '@formkit/core'
import Axios from 'axios'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'

const props = defineProps(['data'])
const store = useStore()
const disabled = ref(false)
const message = ref('')
const library = ref({})
const form = ref(null)
const request = ref({
  type: 'forms',
  id: props.data.forms_module_group.form_id,
})

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validation = []
  if (required) {
    validation.push('required')
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push('number')
    validation.push('length:10,10')
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const selectedForm = computed(() => {
  const schema = []
  form.value.fields.forEach((field) => {
    schema.push({
      $formkit: field.type === 'phone' ? 'tel' : field.type,
      name: field.label,
      // label: field.label,
      placeholder: field.placeholder,
      suffix: '',
      options: selectOptions(field.choices),
      classes: {
        outer: field.cssClass,
      },
      validation: validationTypes(field.isRequired, field.type),
    })
  })
  schema.push({
    $el: 'div',
    children: message.value.replace(/<\/?[^>]+(>|$)/g, ''),
    attrs: {
      class: 'message-container mt-10 text-green-500 text-right',
    },
  })
  return schema
})

const submitButton = computed(() => {
  return `${form.value.button.text}`
})

const getTheForm = () => {
  form.value = store.singleById(request.value)
}

const getForms = () => {
  return store.getSingleById(request.value).then(() => getTheForm())
}

const postData = async (URL, body) => {
  try {
    const response = await Axios.post(URL, body)
    message.value = response.data.confirmation_message
      ? response.data.confirmation_message
      : response.data.validation_messages[1]
    reset('formKit')
    disabled.value = false
  } catch (error) {
    console.error(error)
  }
}

const submitHandler = (data) => {
  disabled.value = true
  let curedForm = {}
  for (const field of Object.entries(data)) {
    curedForm[
      `input_${
        form.value.fields.find((x) => {
          return x.label === field[0]
        }).id
      }`
    ] = field[1]
  }
  postData(form.value.submitURL, curedForm)
}

onBeforeMount(() => getForms())
</script>

<style lang="scss" scoped>
.forms-section {
  @apply text-black py-10 lg:pb-20 pt-10 px-4 xl:px-0;

  .form-group {
    @apply flex flex-col w-full max-w-screen-xl mx-auto;

    .form-group-top {
      @apply w-full;
    }

    .form-area {
      @apply w-full;
    }
  }

  .form-section-text:deep() {
    @apply max-w-2xl text-black flex flex-col pb-10 border-b border-black lg:pb-2;

    * {
      @apply mb-3;
    }

    strong {
      @apply font-normal text-[48px] lg:text-[32px];
    }

    a {
      @apply text-black underline;
    }
  }

  .form-area:deep() {
    @apply w-full;

    .formkit-form
      .form-element
      .formkit-wrapper
      .formkit-inner
      *:last-child::placeholder {
      opacity: 0.6;
    }

    .submit-button-container {
      @apply flex items-end justify-end;
    }

    .formkit-form {
      @apply max-w-screen-xl flex flex-col lg:flex-row flex-wrap gap-x-16 gap-y-10 items-end;

      input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      .formkit-messages {
        @apply text-right;

        li {
          @apply text-red-400 pt-1 pr-3 mr-3;
        }
      }

      & * {
        @apply w-full;
      }

      .formkit-outer {
        @apply mt-0;

        .formkit-messages {
          @apply text-left;

          li {
            @apply text-red-400 pt-1 pr-3 mr-3;
          }
        }
      }

      input,
      textarea,
      select {
        @apply w-full text placeholder-text border-b border-0 pb-2 focus:border-mainBlue focus:outline-none focus:ring-0 focus:shadow-none px-0;
      }

      textarea {
        @apply min-h-[200px];
      }

      .formkit-actions {
        @apply hidden;
      }

      .form-element {
        .formkit-messages {
          @apply text-left relative;
          li {
            @apply absolute text-red-400;
            &:not(:last-child) {
              @apply border-r border-red-300;
            }
          }
        }

        .formkit-wrapper {
          @apply relative;
          .formkit-label {
            // @apply absolute text-[22px] transition-all pointer-events-none;
          }
          .formkit-inner {
            * {
              @apply mb-8;
            }
            *:last-child {
              @apply mb-0;
            }
          }
        }

        &.half-width {
          @apply w-full lg:w-[46.5%] xl:w-[47%];
        }

        &.full-width {
          @apply w-full;
        }
      }

      .gform_confirmation_message {
        @apply text-green-500;
      }

      div[data-type='submit'] {
        @apply flex justify-center mt-10;
      }

      div[data-type='submit'] {
        button:disabled {
          @apply opacity-50 cursor-not-allowed;
        }
      }
    }
  }
}
</style>
