<template>
  <header
    id="header-nav"
    ref="header"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    } ${scrollProgress * 100 > 1 ? 'sticky' : ''}
    `">
    <OverlayMenu
      :submenu="menu"
      :active="navOpen"
      @close="close()" />
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a href="/">
            <Transition
              v-if="device === 'desktop'"
              name="fade"
              mode="out-in">
              <img
                v-if="site.site_logo?.url && !sticky"
                :src="site.site_logo?.url || ''" />
              <img
                v-else
                :src="site.site_logo_sticky?.url || ''" />
            </Transition>
            <Transition
              v-else
              name="fade"
              mode="out-in">
              <img :src="site.site_logo_sticky?.url || ''" />
            </Transition>
          </a>
        </div>
        <div
          class="mobile-nav"
          :class="navOpen ? 'active' : ''"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-three" />
        </div>
      </div>
      <ul
        ref="nav"
        class="nav-container"
        :class="navOpen ? 'inactive' : 'active'">
        <template v-if="introShown">
          <transition-group
            v-for="item in topLevelMenu(menu)"
            :key="item.id"
            tag="li"
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            @click="item.class[0] === '' ? close(false) : null">
            <template v-if="item.parent === '0'">
              <template v-if="item.class[0] === 'search_link'">
                <img
                  id="searchIcon"
                  :key="item.id"
                  :src="searchIcon"
                  alt="Search icon"
                  @click="showSearch" />
                <Search
                  v-if="displaySearch == true"
                  @display-search="showSearch"></Search>
              </template>
              <template v-else>
                <div class="cta-menu">
                  <ButtonGlobal
                    :url="item.url"
                    :text="item.content"
                    :disabled="item.class[0] === 'disabled' ?? false"
                    :xl="false"
                    :white="false"
                    :invert="true"
                    :target="item.target" />
                </div>
                <!-- <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :menu="menu"
                  :item="item"
                  :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                  @clicked="close" /> -->
              </template>
            </template>
          </transition-group>
          <div
            class="desktop-nav"
            :class="navOpen ? 'active' : ''"
            @click="toggle">
            <span class="line line-one" />
            <span class="line line-three" />
          </div>
        </template>
      </ul>
    </nav>
  </header>
</template>

<script setup>
// import NavMenuItem from './NavMenuItem.vue'
import Search from './NavSearch.vue'
import OverlayMenu from './OverlayMenu.vue'
import ButtonGlobal from '../../utility/ButtonGlobal.vue'
import gsap from 'gsap'
import searchIcon from '@/assets/img/search.svg'
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'

const store = useStore()
const menu = ref(store.menu({ name: 'top-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref()
const navOpen = ref(false)
const displaySearch = ref()
const { scrollProgress, device } = useUtils()
const { introShown } = useIntroShown()

const showSearch = () => {
  this.displaySearch = !this.displaySearch

  const html = document.querySelector('html')
  if (this.displaySearch) {
    html.classList.add('no-overflow')
  } else {
    html.classList.remove('no-overflow')
  }
}
const topLevelMenu = (menu) => {
  const topMenu = menu.filter((x) => x.parent === '0')
  return topMenu
}
const toggle = () => {
  navOpen.value = !navOpen.value
}
const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
}
const beforeEnter = (el) => {
  // add the initial style to the elements
  el.style.transform = 'translateY(10px)'
  el.style.opacity = 0
}
const enter = (el) => {
  gsap.to(el, {
    y: 0,
    opacity: 1,
    duration: 0.3,
    delay: el.dataset.ind * 0.3,
  })
}
</script>

<style lang="scss" scoped>
#header-nav {
  @apply transition duration-1000 ease-in-out;
  @apply fixed bg-transparent shadow-none;
  .logo-container img {
    @apply w-44 lg:w-60;
  }
}
#header-nav.sticky {
  @apply top-0 mt-0 lg:fixed shadow-xl fixed;
  @apply bg-white shadow-2xl;
  nav {
    @apply max-h-[70px] border-none;
    .logo-container {
      img {
        @apply h-full max-h-[50px] lg:mt-0;
      }
    }
  }
}
#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px] transition-all duration-[2s] ease-in-out;
  .mobile-nav {
    .line-one {
      @apply transform rotate-45 translate-y-[10px] translate-x-[0px];
    }
    .line-three {
      @apply transform -rotate-45 -translate-y-[0px] -translate-x-[0px];
    }
  }
  .desktop-nav {
    @apply cursor-pointer;
    .line-one {
      @apply transform rotate-45 translate-y-[2px] translate-x-[0px];
    }
    .line-three {
      @apply transform -rotate-45 -translate-y-2 -translate-x-[0px];
    }
  }
}
.header-nav {
  @apply flex mt-0 mb-0 items-stretch
         justify-center w-full top-0 min-h-[70px]
        max-h-[80px] z-10;
  nav {
    @apply flex flex-col lg:flex-row items-stretch lg:h-[80px] lg:max-h-[80px]
    lg:items-center w-full max-w-screen-xl pt-0 xl:px-3 2xl:px-0 lg:py-0
     transition-all duration-300 ease-in-out;
    ul {
      @apply flex flex-col my-3 lg:my-0 lg:flex-row justify-start lg:gap-6 items-center lg:items-end md:px-0 lg:px-5 xl:px-0;
      li:nth-child(1) {
        @apply inline-block ml-0 xl:ml-5 lg:w-auto;
      }
      li {
        @apply w-full lg:w-auto text-center;
      }
    }
  }
  .branding {
    @apply w-full lg:w-[30%] xl:w-[35%] text-center
           md:text-left flex items-center lg:max-h-[65px]
           justify-between p-3 xl:p-0 lg:shadow-none;
    .logo-container {
      @apply h-full xl:pl-2 xl:max-h-full flex items-center lg:inline-block;
      img {
        @apply w-[80%] lg:w-auto h-full max-h-[60px] lg:max-h-[80px];
      }
    }
    .mobile-nav {
      @apply flex flex-col lg:hidden float-right p-3 transform translate-y-1;
      .line {
        @apply mb-2 transition-all duration-[0.5s] ease-in-out;
      }
      .line:before {
        @apply content-[''] block h-[1px] w-[55px] bg-black;
      }
      &.active {
        .line:before {
          @apply content-[''] block h-[1px] w-[55px] bg-white transition-colors duration-1000 ease-in-out;
        }
      }
    }
  }

  .nav-container {
    @apply w-full
           lg:w-[70%] xl:w-[75%] text-center md:text-left
           transition duration-500 ease-in-out m-0 place-items-center justify-end;
    height: fit-content;

    .cta-menu {
      @apply hidden lg:inline-block;
    }

    li {
      @apply transition-opacity duration-300 ease-in-out;
    }
    &.inactive {
      li {
        @apply opacity-0 transition-opacity duration-300 ease-in-out pointer-events-none;
      }
    }

    &.active {
      li {
        @apply opacity-100 transition-opacity duration-300 ease-in-out delay-700;
      }
    }
  }
  .desktop-nav {
    @apply hidden flex-col lg:flex float-right p-3 cursor-pointer z-50;
    .line {
      @apply mb-2 transition-all duration-[0.5s] ease-in-out;
    }
    .line:before {
      @apply content-[''] block h-[1px] w-[55px] bg-black transition-colors duration-1000 ease-in-out;
    }
    &.active {
      .line:before {
        @apply content-[''] block h-[1px] w-[55px] bg-white transition-colors duration-1000 ease-in-out;
      }
    }
  }
}
#searchIcon {
  @apply mt-[5px] pl-5 cursor-pointer mx-auto py-4 lg:py-0;
}
</style>
